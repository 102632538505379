/* eslint-disable jsx-a11y/no-onchange */

import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Search from "../components/search"

const Places = ({ data, location }) => {
  return (
    <Layout>
      <Seo title="Places" location={location} />
      <Search
        searchTitleCaption={"LISTINGS SEARCH RESULTS"}
        location={location}
        allPosts={data.allListingResidential.nodes}
        placesTitle={data.homeContent.placesTitle}
        allLocation={data.allLocation}
        minPriceSliderValue={0}
        maxPriceSliderValue={100000000}
        priceStepSliderValue={process.env.BUY_PROPERTY_PRICE_SLIDER_INCREMENT}
      />
    </Layout>
  )
}

export default Places

export const query = graphql`
  query MyResidentialQuery {
    allListingResidential(filter: { StandardStatus: { ne: "Closed" } }) {
      nodes {
        ListPrice
        BedroomsTotal
        BathroomsTotalInteger
        UnparsedAddress
        PrefferedPhoto
        ListOfficeMlsId
        ListingId
        MLSAreaMajor
        MLSAreaMinor
        parsedAddress
        SubdivisionName
        LivingArea
        ArchitecturalStyle
        LotSizeAcres
        PropertyCondition
        StandardStatus
        OnMarketDate
        PropertyType
        ParkingFeatures
        VirtualTourURLUnbranded
        MajorChangeTimestamp
        MajorChangeType
      }
    }
    homeContent {
      placesTitle
    }
    allLocation(sort: { fields: locationItem___index, order: ASC }) {
      nodes {
        locationItem {
          value
          text
        }
      }
    }
  }
`
